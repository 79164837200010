.page-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 16px;
  margin-bottom: 0;
  background-color: #f6f8f8;
  border-bottom: solid 1px #c0c5cb;
}

.home_status_today,
.home_status_fromto {
  width: 100%;
  display: flex;
  border: solid 1px red;
  padding: 8px;
  font-size: 18px;
  font-weight: 500;
}

.status_value {
  font-weight: bold;
  color: red;
  padding-right: 25px;
  font-size: 20px;
  padding-left: 5px;
}

@media only screen and (max-width: 768px) {
  .page-top-wrapper {
    padding: 5px 8px;
    height: 56px;
  }
}