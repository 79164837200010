.chart-status {
 
}

.chart-status > div > canvas {
  padding: 10px;
  min-height: 500px !important;
}

.home_status {
  display: flex;
  justify-content: space-around;
}
