html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
del,
em,
img,
small,
strong,
b,
u,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
button,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  word-wrap: break-word;
}
html {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: inherit;
  font-weight: inherit;
  vertical-align: middle;
}
q,
blockquote {
  quotes: none;
}
a img {
  border: none;
}
article,
aside,
details,
figure,
footer,
header,
menu,
nav,
section,
summary {
  display: block;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
select:focus,
select:active,
select:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  box-shadow: none !important;
  text-decoration: none;
}

body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }
}

a {
  color: var(--color-blue-5);
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}
html,
body {
  height: 100%;
}
body {
  background-color: #f0f2f5;
}
#root {
  height: calc(100% - 1px);
}
