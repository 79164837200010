.fund {
  .ant-form-item {
    margin-bottom: 10px;
  }
  .form_item_custom {
    .ant-form-item-row {
      display: flex;
      flex-direction: column;
      .ant-form-item-label {
        text-align: initial;
      }
    }
  }
}
