.slider-picture {
  height: 120px;
  width: fit-content;
  max-width: 350px;
}

.td-slider-picture {
  text-align: center;
}

.table-slide {
  overflow-y: auto;
}

@media only screen and (max-width: 1400px) {
  .slider-picture {
    height: 80px;
  }
}

.top_content_wrap {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: normal;
  line-height: 1.23;
  letter-spacing: 0.35px;
  margin: 0;
  color: #101025;
  .ant-row {
    min-height: 60px;
    align-items: center;
    justify-content: space-between;
    button {
      background: #16477af0;
      color: #fff;
      // border-radius: 4px;
    }
  }
}

.slider_list {
  position: absolute;
  top: 62px;
  overflow: auto;
  width: 100%;
  height: calc(100% - 62px);
  z-index: 0;

  .ant-tag {
    cursor: pointer;
  }
}
.ant-table-content {
  .ant-checkbox {
    .ant-checkbox-inner:hover {
      border-color: #f4543c !important;
    }
  }

  .ant-checkbox-inner {
    scale: 1.1;
    background-color: #00a65a;
    border-color: #008d4c;
  }
}

.checkbox_table_false .ant-checkbox-inner {
  background-color: #f56954;
  border-color: #f4543c;
  scale: 1 !important;
}

.checkbox_table_false .ant-checkbox-inner::after {
  content: "\00d7";
  position: absolute;
  border: unset;
  opacity: unset;
  color: #fff;
  top: -2px;
  left: 1.5px;
  transform: rotateY(0deg);
  font-size: 18px;
  align-items: center;
}


@media (max-width: 768px) {
  .slider_list {
    width: 100%;
    top: 62px;
    overflow-x: hidden;
  }
}