* {
  font-family: Source Sans Pro, sans-serif;
  font-variant: tabular-nums;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.ant-radio-inner {
  border-radius: 50%;
}

a {
  color: var(--color-blue-5);
}

.content {
  padding: 16px;

  .ant-table-wrapper {
    overflow: auto;
    overflow-y: hidden;
  }
}

.cursor_pointer {
  cursor: pointer;
}

.ant-popover-inner-content {
  min-width: 200px;
}

.ant-checkbox-checked::after {
  border-color: transparent !important;
}

.rti--tag {
  color: #1677ff;
  background: #e6f4ff;
  border: 1px solid #d9d9d9;

  input {
    width: 100%;
  }
}

.rti--container {
  --rti-main: #ccc !important;
  border-radius: 0px !important;
}

.rti--container:focus-within {
  box-shadow: none !important;
}

.star_icon {
  color: #ecf0f1;
}

.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-select-selector {
  border-color: #ccc !important;
  box-shadow: none !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  padding: 12px 8px;
}

.tt_flag {
  background-color: #ff4e4e;
  border-radius: 16px;
  color: #fff;
  width: fit-content;
  padding: 2px 10px;
  margin: auto;
}

.success_flag {
  background-color: #12aa12;
}

.warning_flag {
  background-color: #fc911f;
}

.warning_flag,
.success_flag {
  border-radius: 4px;
  color: #fff;
  width: fit-content;
  padding: 0px 5px;
}

.bold {
  font-weight: 700;
}

.ant-modal-header {
  text-align: center;
  text-transform: uppercase;
}

.badge {
  border-radius: 20px;
  width: fit-content;
  padding: 0 8px;
  margin-left: 5px;
  background-color: #8d8d8d;
  height: 20px;
  display: flex;
  align-items: center;
  color: #fff;
}

.flex-wrap {
  display: flex;
  align-items: center;
}

.border_form .empty .ant-form-item-control-input {
  border: 1px solid #d9d9d9;
  padding: 6px 10px;
  border-radius: 2px;
  min-height: 38px !important;
}

.about .cke_contents {
  min-height: 400px;
}

.ckeditor_form {
  &__item {
    .ant-form-item-row {
      justify-content: end;
    }

    .ant-form-item-control {
      max-width: 83.33333333% !important;
    }
  }

  .cash_img {
    margin-left: 16.66666667% !important;
  }

  @media (max-width: 767px) {
    &__item {
      .ant-form-item-row {
        justify-content: unset !important;
      }

      .ant-form-item-control {
        max-width: 100% !important;
      }
    }

    .cash_img {
      margin-left: 0 !important;
    }

 
  }
}

@media (max-width: 767px) {
  .ant-form-item-label>label {
    height: 13px !important;
  }

  .ant-card-body {
    padding: 8px;
  }

  .main_wrap .content {
    height: calc(100% - 64px);
    overflow-y: auto;
    padding: 8px;
    overflow-x: hidden;
  }

  input[name="discount_number"] {
    width: 100%;
  }
}

.ant-form-item-label>label {
  white-space: initial;
  align-items: center;
}

.ant-tabs-card.ant-tabs-top .ant-tabs-tab {
  padding: 10px;
}

.ant-form input[type="file"] {
  height: 38px !important;
  padding: 4px !important;
}

.ant-tabs-nav-operations {
  display: none !important;
}

@media (max-width: 375px) {
  .ant-card-body {
    overflow: hidden;
    overflow-x: auto;
  }
}