/* width */


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-scrolling-effect {
  width: 100% !important;
}

@media only screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 5px;
  }
}