.loading_full {
  transform: translateX(-16px);
}
.loading,
.loading_full {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999999;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  background: #e0e0e05e;
}
