.block_item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  background-color: grey;
  color: #fff;
  padding: 2px 10px;
  border-radius: 16px;
}
