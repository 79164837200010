:root {
  --color-crystal: #0000;
  --color-black: #000000;

  --color-cyan-1: #e6fffb;
  --color-cyan-4: #5cdbd3;
  --color-cyan-5: #36cfc9;
  --color-cyan-6: #13c2c2;
  --color-cyan-7: #08979c;

  --color-neutral-1: #ffffff;
  --color-neutral-3: #f5f5f5;
  --color-neutral-4: #f0f0f0;
  --color-neutral-5: #d9d9d9;
  --color-neutral-6: #bfbfbf;
  --color-neutral-7: #8c8c8c;
  --color-neutral-8: #595959;
  --color-neutral-9: #434343;
  --color-neutral-10: #fafafa;

  --color-orange-6: #fa8c16;

  --color-red-4: #ff7875;
  --color-danger: #ff4d4f;

  --color-border-primary: #667080;

  --color-blue-5: #597ef7;
  --color-blue-6: #1890ff;

  --color-green-5: #73d13d;

  --color-white: #ffffff;
  --color-grey: #cbcbcb;

  --bg-button-disabled: #cbcbcb;
  --color-table-header: #b2b2b2;

  --primary-color: #49abb1;
  --second-color: #f2f2f2;
}
