.import_auto {
  display: flex;
  justify-content: space-between;
  // padding-top: 20px;
  padding-bottom: 8px;
}

.import_auto > div {
  display: flex;
  justify-content: space-between;
  width: 250px;
}

.import_auto_check {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 5px;
}

.form_import .ant-form-item-control > div:nth-child(2) {
  // display: none !important;
}

.import_auto_check_messge_true {
  color: green
}

.import_auto_check_messge_false {
  color: red
}

.import_auto_button {
  width: 200px;
}
