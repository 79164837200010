.plus {
  place-items: center;
  padding: 20px 0px;
}

.day_expires_plus {
  @media (max-width: 768px) {
    padding-left: 20px; 
  }
}

.day_expires_plus .ant-form-item-row {
  place-items: center;
}

.day_expires_plus .ant-form-item-label {
  max-width: unset;
  flex: auto;
  width: 0px;
}

.day_expires_plus .ant-form-item-control {
  display: flex;
  flex-direction: row;
  width: 274px;
}

.account_form {
  .ant-col-md-16 {
    max-width: 83.33333333% !important;
  }
  .vip .ant-col-md-16 {
    max-width: 66.66666667% !important;
  }
  @media (min-width: 992px) {
    .vip {
      .ant-row {
        justify-content: end;
        gap: 10px;
      }
    }
  }
  @media (max-width: 992px) and (min-width: 767px) {
    .vip .ant-col-md-16 {
      max-width: 83.33333333% !important;
    }
  }
  @media (max-width: 767px) {
    .vip .ant-col-md-16,
    .ant-col-md-16 {
      max-width: 100% !important;
    }
    .vip .ant-row {
      justify-content: unset !important;
      gap: 0px !important;
    }

    .update_user_item_radio {
      margin-bottom: 0px;
    }

    .update_vip_account > label {
      padding-bottom: 10px;
    }
  }
}

.update_user_item_radio .ant-form-item-control {
  align-self: center;
}
