.content {
  .ant-tabs-nav {
    margin: 0;
  }

  .ant-col,
  .ant-tabs-nav,
  .ant-tabs-content {
    position: unset !important;
  }
}

.setting-loading {
  background: white;
  height: 600px;
}
.field_custom {
  width: fit-content;
}

.khuyennghi_row {
  display: flex;
  justify-content: flex-end;
}
