.error-field {
  textarea,
  .ant-picker,
  .ant-input-affix-wrapper,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  input {
    border: 1px solid #eb5757 !important;
    box-shadow: 0px 0px 15px rgba(232, 78, 78, 0.35) !important;
  }
  .ant-input-affix-wrapper {
    input {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .ant-picker {
    input {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

input[type="number"] {
  width: 125px;
}
.ant-input-password {
  // border-radius: 4px;

  input {
    border: none !important;
    box-shadow: none !important;
  }
}
.ant-form-item-with-help {
  margin: 0;
}
.ant-input {
  &:hover,
  &:focus {
    border-color: #ccc;
  }
}
