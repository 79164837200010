.two_factor {
  &__item {
    margin-bottom: 8px;

    li {
      list-style-type: decimal;
    }
    h4 {
      font-size: 16px;
      color: rgb(35 99 235);
      border-bottom: 1px solid #e5e7eb;
      margin-bottom: 8px;
    }
    .qr_wrap {
      width: 256px;
      height: 256px;
      margin: auto;
      img {
        width: 100%;
      }
    }
    .qr_input {
        width: 50%;
    }
  }
}
