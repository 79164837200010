.register-form {
  .ant-col {
    display: flex;
    justify-content: end;
    @media (max-width: 767px) {
      justify-content: initial !important;
    }
  }
  .ant-form {
    .ant-form-item {
      margin: 0;
      padding: 8px 0;
    }
  }
  textarea,
  input {
    background-color: inherit !important;
    color: #101025 !important;
  }
}
