.app {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  height: 100%;
  width: 100%;
  min-width: 320px;
  // overflow-x: auto;
}

.ant-form-item-control-input {
  min-height: unset !important;
}
// .ant-radio-inner,
// .ant-radio-checked .ant-radio-inner {
//   border-radius: 0 !important;
// }
// .ant-radio-checked .ant-radio-inner::after {
//   border-radius: 0 !important;
// }
// .ant-radio-checked .ant-radio-inner::after {
//   position: absolute;
//   display: unset;
//   border: unset;
//   opacity: unset;
//   font-size: 12px;
//   color: #fff;
//   left: 50%;
//   transform: rotateY(0deg);
//   content: "✓";
//   width: 16px;
//   height: 16px;
//   bottom: 0;
//   text-align: center;
//   -webkit-transform: checkbox;
//   -ms-transform: checkbox;
//   transform: checkbox;
// }
