.register_common {
  &__form {
    .ant-form-item-row {
      display: flex;
      flex-direction: column;
      .ant-form-item-label {
        text-align: start;
      }
    }
    .ant-form-item-explain {
      display: none;
    }
    .ant-col {
      height: fit-content;
    }
    .ant-form-item {
      margin: 0;
    }
    .ant-picker {
      width: 100%;
      // border-radius: 4px;
    }
  }
  .primary_btn {
    background-color: #4949ff;
    color: #fff;
    // border-radius: 4px;
  }
  .export_btn {
    background-color: #12984a;
    color: #fff;
    // border-radius: 4px;
  }
}
