.cash_img {
  display: flex;
  justify-content: left;
  margin: 20px 0;
  margin-left: 150px;

  img {
    min-width: 200px;
    width: fit-content;
    height: 150px;
    object-fit: contain;
    padding-right: 10px;
  }
}

.cash_form {
  margin: auto;
  max-width: 500px;
  display: flex;
  float: left;
}

.cash-form-item {
  display: flex;
  height: 43px;
  margin-bottom: 15px;
}


.cash-form-item>span {
  align-self: center;
  font-size: 12px;
  font-weight: 700;
  margin-right: 8px;
  width: 130px;
  text-align: right;
}

.cash-form-item .ant-col-sm-16 {
  max-width: unset;
}

.cash-form-item .ant-form-item {
  width:289px;
}