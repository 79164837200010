.slider-form {
  .ant-input-suffix {
    .ant-btn-primary {
      position: absolute;
      right: -1px;
    }
  }
}
.number_input {
  input {
    width: 100%;
  }
}