.ant-picker {
  padding: 12px 10px;
  height: 36px;

  &.ant-picker-disabled {
    .ant-picker-input {
      input[disabled] {
        color: #828282;
      }
    }
    &:hover {
      cursor: default;
      border: none;
      box-shadow: none !important;
    }
  }
  .ant-picker-suffix {
    pointer-events: unset;
  }
}
.ant-picker.ant-picker-disabled {
  cursor: default !important;
  .anticon[tabindex] {
    cursor: default !important;
  }
}
.ant-picker-input > input[disabled] {
  cursor: default !important;
}
