// .tuvan_slider-form {
//   display: flex;
//   flex-direction: column;
//   .ant-form-item-control-input-content {
//     position: relative;
//   }
// }

// .tuvan_slider-form .ant-form-item-label {
//   min-width: 150px;
// }

.iframe_tu_van {
  height: 680px;
  margin-bottom: 20px;
}

.tuvan .cke_contents {
  min-height: 400px;
}