.top_content_wrap {
  min-height: 60px;
  padding: 0 16px;
  background-color: #f4f9fd;
  border-bottom: solid 1px #c0c5cb;
  border-top: solid 1px #c0c5cb;
}

.top_content_wrap > .ant-row > .ant-col >b  {
  font-size: 15px;
}

.top_content_wrap > .ant-row > .ant-col {
  font-size: 15px;
}
