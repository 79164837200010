.import_right {
  display: flex;
}
.import_right_button {
  display: flex;
  margin-left: 20px;
  align-items: center;

}

.import_right_alert .ant-alert-action {
  align-self: center;
}


.import_right_alert .ant-alert-action .ant-btn {
  background: transparent;
  color: #000;
}

.import_right_wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}