.chart-status {
  background: white;
  min-height: 500px;
}

.chart-status > div > canvas {
  padding: 10px;
}

.home_status {
  display: flex;
  justify-content: space-around;
}
