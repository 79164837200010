.main_wrap {
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;

  .ant-layout-sider {
    z-index: 999;
    .ant-layout-sider-children {
      width: inherit;
      position: fixed;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #333;
    height: 64px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    img {
      height: 60px;
      width: 75px;
    }
  }

  .ant-layout-sider-collapsed,
  .ant-layout-sider-collapsed .ant-layout-sider-children {
    width: 80px;
  }

  .nav_wrap {
    margin-top: 64px;
    padding-bottom: 48px;

    .ant-menu-submenu ul {
      background-color: #333;
    }

    .ant-menu-title-content {
      font-size: 13px;
      font-weight: 400;
    }
  }

  .right_side {
    position: relative;
    width: 100%;
    background-color: #f0f2f5;
    height: 100%;
    overflow: auto;
  }

  .ant-layout-sider-children {
    background-color: #333;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider-form .ant-form-item-no-colon {
    font-size: 13px;
    font-weight: 600;
  }

  .ant-form-item-label > label {
    font-size: 13px;
    font-weight: 600;
  }

  .slider-form .ant-form-item-control-input {
    max-width: 550px;
  }

  .slider-form .ant-form-item {
    float: left;
    display: flex;
  }

  .slider-form {
    display: flex;
    flex-direction: column;
    .ant-form-item-control-input-content {
      position: relative;
    }
  }

  .slider-form .ant-form-item-label {
    min-width: 150px;
  }
  .slider_checkbox {
    margin-left: 150px !important;
  }
  .checkbox {
    margin-left: 16.66666667%;
  }

  .slider-form .ant-form-item {
    min-width: 150px;
  }

  .slider-form .ant-form-item-control {
    min-width: 700px !important;
  }

  .tuvan .slider-form .ant-form-item-control {
    min-width: unset !important;
  }

  .tuvan .slider-form .ant-form-item-row {
    width: 100%;
  }

  // .slider-form .ant-form-item-control>div:nth-child(2) {
  //   display: none !important;
  // }

  @media only screen and (max-width: 1150px) {
    .slider-form .ant-form-item-control-input {
      max-width: unset;
    }

    .slider-form .ant-form-item-control {
      min-width: unset !important;
    }

    .slider-form .ant-form-item {
      min-width: unset;
    }

    .slider-form .ant-form-item-row {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .slider-form .ant-form-item-no-colon {
      float: left;
    }

    .cash_img {
      margin: 20px 0;
    }
    .slider_checkbox,
    .checkbox {
      margin-left: unset !important;
    }
    .mm-close {
      display: block;
    }

    .ant-layout-sider-trigger {
      display: none;
    }
  }
  .ant-layout-sider-trigger,
  .ant-menu-item,
  .ant-menu.ant-menu-dark {
    background-color: #333;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
  }
  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    background-color: #ccc !important;
  }
  .ant-menu-item-icon {
    color: unset !important;
  }
  .ant-menu-submenu-selected,
  .ant-menu-dark .ant-menu-inline.ant-menu-sub,
  .ant-menu-sub,
  .ant-menu-sub .ant-menu-item,
  .ant-menu-sub a {
    color: #eee !important;
    background-color: transparent;
  }
  .ant-menu-sub {
    .ant-menu-item-selected {
      color: #eee !important;
      a {
        background-color: inherit;
      }
    }
  }
  .ant-menu-item-selected {
    color: #eee !important;
    background-color: #444 !important;
    a {
      background-color: inherit;
    }
  }
}
.collapse .right_side {
  width: 100% !important;
}

.mm-close {
  position: absolute;
  right: 4px;
  height: 25px;
  width: 24px;
  z-index: 1000;
  color: white;
  top: 0px;
  text-align: center;
  font-size: 17px;
  display: none;
}

.mm-close:after {
  content: "x";
}