.table {
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.05);
  // border-radius: 2px;
  background-color: #fff;

  thead {
    border: none;

    th {
      border-right: 1px solid #ddd !important;
      border-bottom: 1px solid #ddd !important;
    }
  }

  .ant-table-title,
  .ant-table-container,
  .ant-table-footer {
    border-left: none !important;
    border-right: none !important;
  }

  .ant-table-content {
    tr {

      th:last-child,
      td:last-child {
        // border-right: none !important;
      }

      font-size: 12px;

      td {
        border-right: 1px solid #ddd !important;
        border-bottom: 1px solid #ddd !important;
        border-top: none;
      }
    }
  }

  &__header {
    &__extend {
      padding: 0 22px;
    }
  }

  .ant-table-title {
    padding: 0;

    h4 {
      background-color: #f6f8f8;
      height: 50px;
      padding: 14px 0;
      margin: 0;
      font-weight: 500;
      font-size: 13px;
      border-bottom: 1px solid #000;
    }
  }

  .ant-table-row-expand-icon {
    display: none;
  }

  .ant-table-footer {
    background: #ffffff;
    padding-bottom: 34px;
  }

  .ant-table-pagination {
    .ant-pagination-total-text {
      position: absolute;
      left: 16px;
    }
  }

  &.showTotal {
    .ant-table-pagination {
      padding-right: 9px;
    }
  }

  @media (max-width: 1060px) {
    .ant-pagination-total-text {
      width: 100%;
      position: relative !important;
    }
  }

  .ant-table-row.ant-table-row-level-1 {
    &:hover {
      td {
        background-color: #ffffff;
      }
    }

    .ant-table-cell.ant-table-cell-with-append {
      text-indent: -9999px;
      border-bottom-color: transparent;
    }

    td:last-child {
      text-indent: -9999px;
      border-bottom-color: transparent;
    }

    +.ant-table-row.ant-table-row-level-0 {

      td:last-child,
      td:first-child {
        border-top: 1px solid #f0f0f0;
      }
    }
  }

  .table-row-dark {
    background-color: #f3f4f5;

    .ant-table-cell-fix-right {
      background-color: #f9fafc;
    }
  }

  .ant-table-container {
    overflow: auto;
  }

  .ant-select-selector {
    height: 32px !important;

    .ant-select-selection-item {
      line-height: 32px;
    }
  }

  &.hover {
    tbody tr td {
      cursor: pointer;
    }
  }
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background-color: inherit !important;
}

table {
  border-left: 1px solid #ddd !important;
  border-top: 1px solid #ddd !important;
}


@media only screen and (max-width: 768px) {
  .ant-table-pagination {
    .ant-pagination-total-text {
      display: none;
    }
  }
}