.list_emails_wrap {
  .top_content_wrap {
    color: #101025;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    .ant-row {
      min-height: 60px;
      align-items: center;
      justify-content: space-between;
    }
  }
}
