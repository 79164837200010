.copy_wrap {
  background-color: #3c8dbc;
  height: 19px;
  width: 19px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid #367fa9;
  cursor: pointer;
  svg {
    height: 12px;
    width: 12px;
  }
}
