.home_wrap {
  padding: 10px;
  width: 100%;
  height: calc(100% - 62px);
  position: absolute;
  overflow: auto;
}

.home_wrap_left {
  padding-right: 5px;
}

.home_wrap_right {
  padding-left: 5px;
}

.overview_row {
  margin-top: 10px;
}

.overview_new {
  margin-top: 10px;
}


.orver_view_wrap .ant-form-item-with-help .ant-form-item-explain {
  min-height: 0px;
}


.div-status-chart .ant-table-content {
  height: 439px;
}


.div-status-chart .ant-table-wrapper {
  max-height: 500px;
}


@media only screen and (max-width: 768px) {
  .div-status-chart {
    margin-bottom: 15px;
  }

  .home_wrap {
    top: 62px;
  }

  .div-status-chart .ant-table-content {
    max-height: 439px;
    height: auto;
  }
}