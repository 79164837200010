.header-wrap {
  background-color: #fff;
  padding: 8px 16px;
  height: 62px;


  .ant-row-end {
    align-items: center;
    gap: 10px;
  }
}

.btnMenu {
  font-size: 16px !important;
  line-height: 30px;
  padding: 0px 10px;
  text-align: center;
  background: black;
  border-radius: 5px;
  float: left;
  margin-left: -2px;
  cursor: pointer;
  height: 30px;
  display: none;
}

.btnMenu i {
  color: #fff !important;
  margin: 0px !important;
}

@media only screen and (max-width: 768px) {
  .header-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    z-index: 998;
  }
  .btnMenu {
    font-size: 16px !important;
    line-height: 30px;
    padding: 0px 10px;
    text-align: center;
    background: black;
    border-radius: 5px;
    float: left;
    margin-left: -2px;
    cursor: pointer;
    height: 30px;
    display: block;
  }
}