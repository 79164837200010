.login {
  height: 100vh;
  background: linear-gradient(
    156.45deg,
    #bdf0f9 7.32%,
    #b8eef8 12.01%,
    #abe9f4 17.34%,
    #94e0ee 23%,
    #2f6e96 76.21%,
    #39507d 97.01%,
    #403e6e 114.78%,
    #423768 127.43%
  );
  h1 {
    position: absolute;
    top: 16%;
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
  }
  form {
    max-width: 376px;
    width: 100%;
    margin: auto;
    background: rgba(255, 255, 255, 0.9);
    padding: 24px 24px 36px;
    // border-radius: 16px;
    .ant-form-item-row {
      flex-direction: column;
      label {
        text-align: initial;
        width: 100%;
      }
    }
    button {
      margin-top: 30px;
      width: 100%;
      // border-radius: 28px;
      height: 46px;
      font-weight: 400;
      font-size: 16px;
    }
  }
  &__wrap {
    position: relative;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
